<template>
    <div>
        <div class="container-fluid p4" style="margin-top: 35px">
            <b-card>
                <b-card-title style="margin-bottom: 45px">
                    Product Master
                    <div class="float-right">
                        <b-btn variant="success" size="md" shape="pill"
                                 @click="GotoAdd"> +
                        </b-btn>
                    </div>
                </b-card-title>
                <b-modal id="addProductModal" modal-class="modal-right" size="lg" hide-footer title="Add Product">
                <AddProduct @success="AddedNew"></AddProduct>
                </b-modal>
                <VueTable ref="table" :url="apiBase" :fields="fields" :perPage="10">
                    <template slot="product_image" slot-scope="props">
                        <img :src='prefix + props.rowData.product_image'
                             width="96" v-if="props.rowData.product_image">
                        <img src="../../../assets/img/logo-white.svg" width="96" v-if="!props.rowData.product_image">
                    </template>
                    <template slot="actions" slot-scope="props">
                        <div class="btn-group">
                            <b-btn class="btn btn-info btn-sm" @click="editItem(props.rowData)">
                                 Edit</b-btn>
                            <b-btn v-if="props.rowData.disabled" class="btn btn-success btn-sm" @click="changeStatus(props.rowData.id)">
                                 Enable</b-btn>
                            <b-btn v-else class="btn btn-warning btn-sm" @click="changeStatus(props.rowData.id)">
                                 Disable</b-btn>
                            <b-btn class="btn btn-danger btn-sm" @click="deleteItem(props.rowData.id)">
                                 Delete</b-btn>
                        </div>
                    </template>
                </VueTable>
            </b-card>
            <b-modal id="editProductModal" modal-class="modal-right" size="lg" hide-footer title="Edit Product">
                <EditProduct :model="editingModel" @saved="ItemEdited"></EditProduct>
            </b-modal>
        </div>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '@/data/urls';
import VueTable from 'v-tables/src/VueTable';
import AddProduct from '@/views/admin/product/AddProduct';
import EditProduct from '@/views/admin/product/EditProduct';

export default {
    name: 'ListProduct',
    data () {
        return {
            editingModel: {
                id: '',
                product_name: '',
                product_category: '',
                mrp: '',
                selling_price: '',
                product_image: '',
                description: '',
                quantity: '',
                return_in: '',
                display_name: ''
            },
            prefix: urls.baseUrl,
            editingProduct: null,
            apiBase: urls.admin.productMaster.list,
            fields: [
                {
                    name: 'id',
                    sortField: '',
                    title: 'ID',
                    titleClass: ''
                },
                {
                    name: '__slot:product_image',
                    sortField: 'product_image',
                    titleClass: ''
                },
                {
                    name: 'product_name',
                    sortField: 'product_name',
                    title: 'Product Name',
                    titleClass: ''
                },
                {
                    name: 'product_category.label',
                    title: 'Category',
                    titleClass: ''
                },
                {
                    name: 'mrp',
                    sortField: 'mrp',
                    title: 'MRP',
                    titleClass: ''
                },
                {
                    name: 'selling_price',
                    sortField: 'selling_price',
                    title: 'Selling Price',
                    titleClass: ''
                },
                {
                    name: 'return_in',
                    sortField: 'return_in',
                    title: 'Return In',
                    titleClass: ''
                },
                {
                    name: '__slot:actions',
                    title: '',
                    titleClass: 'center aligned text-right',
                    dataClass: 'center aligned text-right'
                }
            ]
        };
    },
    methods: {
        GotoAdd () {
            this.$bvModal.show('addProductModal');
        },
        AddedNew () {
            this.$bvModal.hide('addProductModal');
            this.$refs.table.refreshTable();
        },
        editItem (item) {
            this.editingProduct = item;
            this.editingModel.id = item.id;
            this.editingModel.product_name = item.product_name;
            this.editingModel.product_category = item.product_category;
            this.editingModel.mrp = item.mrp;
            this.editingModel.selling_price = item.selling_price;
            this.editingModel.product_image = item.product_image;
            this.editingModel.description = item.description;
            this.editingModel.quantity = item.quantity;
            this.editingModel.return_in = item.return_in;
            this.editingModel.display_name = item.display_name;
            this.$bvModal.show('editProductModal');
        },
        ItemEdited () {
            this.editingProduct.id = this.editingModel.id;
            this.editingProduct.product_name = this.editingModel.product_name;
            this.editingProduct.product_category = this.editingModel.product_category;
            this.editingProduct.mrp = this.editingModel.mrp;
            this.editingProduct.selling_price = this.editingModel.selling_price;
            this.editingProduct.product_image = this.editingModel.product_image;
            this.editingProduct.description = this.editingModel.description;
            this.editingProduct.quantity = this.editingModel.quantity;
            this.editingProduct.return_in = this.editingModel.return_in;
            this.editingProduct.display_name = this.editingModel.display_name;
            this.$bvModal.hide('editProductModal');
            this.editingProduct = null;
            this.$refs.table.refreshTable();
        },
        deleteItem (item) {
            const component = this;
            const submit = confirm('Are you sure to delete..!');
            if (!submit) {
                return;
            }
            axios.form(urls.admin.productMaster.delete, { id: item }).then(function (data) {
                const json = data.data;
                if (json.error === false) {
                    component.$notify('Deleted', 'Product is Deleted Successfully',
                        {
                            duration: 3000,
                            permanent: false,
                            color: 'warning'
                        }
                    );
                    component.$refs.table.refreshTable();
                } else {
                    console.log(json);
                    component.$notify('Error', json.errors,
                        {
                            duration: 3000,
                            permanent: false
                        }
                    );
                }
            });
        },
        changeStatus (product) {
            if (!confirm('Are you sure?')) {
                return;
            }
            const component = this;

            axios.form(urls.admin.productMaster.disableEnable, {
                id: product
            }).then(function (data) {
                const json = data.data;
                if (json.error === false) {
                    component.$notify('Success', '');
                    component.$refs.table.refreshTable();
                } else {
                    console.log(json);
                    component.$notify('Error', json.errors,
                        {
                            duration: 3000,
                            permanent: false
                        }
                    );
                }
            });
        }
    },
    components: { AddProduct, EditProduct, VueTable }
};
</script>

<style scoped>

</style>
