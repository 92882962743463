<template>
    <div>
        <validation-observer v-slot="{handleSubmit}" ref="formValidator">
            <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                <div class="alert alert-danger" v-if="errorMessage" v-html="errorMessage"></div>
                <div class="alert alert-info" v-if="info_message" v-html="info_message"></div>
                <b-row>
                    <b-col>
                        <validated-input alternative name="Product Name"
                                         :rules="{required: true}"
                                         label="Product Name" :disabled="loading"
                                         v-model="model.product_name"></validated-input>
                    </b-col>
                    <b-col>
                        <validated-vue-select :disabled="loading" alternative
                                              class="mb-3" label="Product Category"
                                              name="Product Category" multiple
                                              :optionsURL="CategoryOptions"
                                              v-model="model.product_category"/>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <validated-input alternative name="MRP" placeholder="Add MRP in paisa"
                                         :rules="{required: true}"
                                         label="MRP" :disabled="loading"
                                         v-model="model.mrp"></validated-input>
                    </b-col>
                    <b-col>
                        <validated-input alternative name="Selling Price" placeholder="Add price in paisa"
                                         :rules="{required: true}"
                                         label="Selling Price" :disabled="loading"
                                         v-model="model.selling_price"></validated-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <validated-input alternative name="Description"
                                         :rules="{required: true}"
                                         label="Description" :disabled="loading"
                                         v-model="model.description"></validated-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <label class="form-control-label">Product Image</label>
                        <b-form-file :disabled="loading" :rules="{required: true}" alternative
                                     class="mb-3" id="Product Image" :state="Boolean(model.product_image)"
                                     label="Product Image" name="Product Image"
                                     v-model="model.product_image"></b-form-file>
                    </b-col>
                    <b-col>
                        <validated-input alternative name="Quantity"
                                         :rules="{required: true}"
                                         label="Quantity" :disabled="loading"
                                         v-model="model.quantity"></validated-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <validated-input alternative name="Return IN"
                                         :rules="{required: true}"
                                         label="Return IN" :disabled="loading"
                                         v-model="model.return_in"></validated-input>
                    </b-col>
                    <b-col>
                        <validated-input alternative name="Display Name"
                                         :rules="{required: true}"
                                         label="Display Name" :disabled="loading"
                                         v-model="model.display_name"></validated-input>
                    </b-col>
                </b-row>

                <base-button type="primary" native-type="submit"
                             :disabled="loading">
                                        <span v-show="loading">
                                            <span>Saving...</span>
                                        </span>
                    <span v-show="!loading">ADD</span>
                </base-button>
            </b-form>
        </validation-observer>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '@/data/urls';

export default {
    name: 'AddProduct',
    data () {
        return {
            model: {
                product_name: '',
                product_category: '',
                mrp: '',
                selling_price: '',
                product_image: null,
                description: '',
                quantity: '',
                return_in: '',
                display_name: ''
            },
            loading: false,
            errorMessage: '',
            info_message: '',
            CategoryOptions: urls.admin.category.options
        };
    },
    methods: {
        onSubmit () {
            const formValidator = this.$refs.formValidator;
            this.loading = true;
            const component = this;
            axios.form(urls.admin.productMaster.addEdit, component.model).then(function (data) {
                const json = data.data;
                if (json.error === false) {
                    component.$notify('Added', 'Product is Added Successfully',
                        {
                            duration: 3000,
                            permanent: false
                        }
                    );
                    component.$emit('success');
                } else {
                    console.log(json);
                    formValidator.setErrors(json.errors);
                    component.errorMessage = json.errors.__all__ || '';
                    component.$notify('warning', 'Error', json.errors,
                        {
                            duration: 3000,
                            permanent: false
                        }
                    );
                }
                component.loading = false;
            }).catch(function () {
                component.loading = false;
            });
        }
    }
};
</script>

<style scoped>

</style>
